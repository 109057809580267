/*
 * Automatically generated by SVGR from assets/icons/*.svg.
 * Do not edit this file or add other components to this directory.
 */
import type { SVGProps } from 'react'
export function InfoDarkIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={19}
      height={19}
      fill='none'
      {...props}
    >
      <circle cx={9.755} cy={9.416} r={6.655} fill='#E9EDEF' />
      <mask
        id='info-dark_svg__a'
        width={19}
        height={19}
        x={0}
        y={0}
        maskUnits='userSpaceOnUse'
        style={{
          maskType: 'alpha',
        }}
      >
        <path fill='#D9D9D9' d='M.755.416h18v18h-18z' />
      </mask>
      <g mask='url(#info-dark_svg__a)'>
        <path
          fill='#9DA1A9'
          d='M9.005 13.166h1.5v-4.5h-1.5zm.75-6q.32 0 .535-.216a.73.73 0 0 0 .215-.534.73.73 0 0 0-.215-.534.73.73 0 0 0-.535-.216.73.73 0 0 0-.534.216.73.73 0 0 0-.216.534q0 .319.216.534a.72.72 0 0 0 .534.216m0 9.75a7.3 7.3 0 0 1-2.925-.59 7.6 7.6 0 0 1-2.38-1.603 7.6 7.6 0 0 1-1.604-2.382 7.3 7.3 0 0 1-.59-2.925 7.3 7.3 0 0 1 .59-2.925 7.6 7.6 0 0 1 1.603-2.38A7.6 7.6 0 0 1 6.83 2.506a7.3 7.3 0 0 1 2.925-.59q1.557 0 2.925.59a7.6 7.6 0 0 1 2.382 1.603 7.6 7.6 0 0 1 1.602 2.381 7.3 7.3 0 0 1 .591 2.925 7.3 7.3 0 0 1-.59 2.925 7.6 7.6 0 0 1-1.603 2.382 7.6 7.6 0 0 1-2.382 1.602 7.3 7.3 0 0 1-2.925.591m0-1.5q2.513 0 4.257-1.743t1.743-4.257-1.743-4.256-4.257-1.744T5.5 5.16Q3.756 6.904 3.755 9.416T5.5 13.673t4.256 1.743'
        />
      </g>
    </svg>
  )
}
