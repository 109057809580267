/*
 * Automatically generated by SVGR from assets/icons/*.svg.
 * Do not edit this file or add other components to this directory.
 */
import type { SVGProps } from 'react'
export function InfoIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={18}
      height={19}
      fill='none'
      {...props}
    >
      <mask
        id='info_svg__a'
        width={18}
        height={19}
        x={0}
        y={0}
        maskUnits='userSpaceOnUse'
        style={{
          maskType: 'alpha',
        }}
      >
        <path fill='#D9D9D9' d='M0 .5h18v18H0z' />
      </mask>
      <g mask='url(#info_svg__a)'>
        <path
          fill='#D5D8DC'
          d='M8.25 13.25h1.5v-4.5h-1.5zm.75-6q.319 0 .535-.216A.73.73 0 0 0 9.75 6.5a.73.73 0 0 0-.215-.535A.73.73 0 0 0 9 5.75a.73.73 0 0 0-.534.215.73.73 0 0 0-.216.535q0 .319.216.534A.72.72 0 0 0 9 7.25M9 17a7.3 7.3 0 0 1-2.925-.591 7.6 7.6 0 0 1-2.381-1.603 7.6 7.6 0 0 1-1.603-2.381A7.3 7.3 0 0 1 1.5 9.5a7.3 7.3 0 0 1 .591-2.925 7.6 7.6 0 0 1 1.603-2.381A7.6 7.6 0 0 1 6.075 2.59 7.3 7.3 0 0 1 9 2q1.555 0 2.925.59a7.6 7.6 0 0 1 2.381 1.604 7.6 7.6 0 0 1 1.603 2.381A7.3 7.3 0 0 1 16.5 9.5a7.3 7.3 0 0 1-.591 2.925 7.6 7.6 0 0 1-1.603 2.381 7.6 7.6 0 0 1-2.381 1.603A7.3 7.3 0 0 1 9 17m0-1.5q2.513 0 4.256-1.744T15 9.5t-1.744-4.256Q11.513 3.5 9 3.5T4.744 5.244 3 9.5t1.744 4.256Q6.487 15.5 9 15.5'
        />
      </g>
    </svg>
  )
}
