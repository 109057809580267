/*
 * Automatically generated by SVGR from assets/icons/*.svg.
 * Do not edit this file or add other components to this directory.
 */
import type { SVGProps } from 'react'
export function OnlineStatusOnlineIcon(
  props: SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={24}
      fill='none'
      {...props}
    >
      <path
        fill='#9DA1A9'
        d='M6.5 20q-2.275 0-3.887-1.575T1 14.575q0-1.95 1.175-3.475Q3.349 9.575 5.25 9.15q.625-2.3 2.5-3.725T12 4q2.925 0 4.962 2.037T19 11q1.725.2 2.863 1.488A4.4 4.4 0 0 1 23 15.5q0 1.875-1.312 3.188Q20.375 20 18.5 20zm0-2h12q1.05 0 1.775-.725T21 15.5t-.725-1.775T18.5 13H17v-2q0-2.075-1.463-3.537Q14.075 6 12 6 9.925 6 8.463 7.463 7 8.925 7 11h-.5q-1.45 0-2.475 1.025A3.37 3.37 0 0 0 3 14.5q0 1.45 1.025 2.475A3.37 3.37 0 0 0 6.5 18'
      />
      <path
        fill='#27AE60'
        d='M16 11.35 10.35 17 6.8 13.45l1.425-1.425 2.1 2.1L14.55 9.9z'
      />
    </svg>
  )
}
