/*
 * Automatically generated by SVGR from assets/icons/*.svg.
 * Do not edit this file or add other components to this directory.
 */
import type { SVGProps } from 'react'
export function ThermostatCoolLargeIcon(
  props: SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={24}
      fill='none'
      {...props}
    >
      <mask
        id='thermostat-cool-large_svg__a'
        width={24}
        height={24}
        x={0}
        y={0}
        maskUnits='userSpaceOnUse'
        style={{
          maskType: 'alpha',
        }}
      >
        <path fill='#D9D9D9' d='M0 0h24v24H0z' />
      </mask>
      <g mask='url(#thermostat-cool-large_svg__a)'>
        <path
          fill='#6B95FF'
          d='M11 22v-4.15l-3.25 3.2-1.4-1.4L11 15v-2H9l-4.65 4.65-1.4-1.4L6.15 13H2v-2h4.15l-3.2-3.25 1.4-1.4L9 11h2V9L6.35 4.35l1.4-1.4L11 6.15V2h2v4.15l3.25-3.2 1.4 1.4L13 9v2h2l4.65-4.65 1.4 1.4-3.2 3.25H22v2h-4.15l3.2 3.25-1.4 1.4L15 13h-2v2l4.65 4.65-1.4 1.4-3.25-3.2V22z'
        />
      </g>
    </svg>
  )
}
