/*
 * Automatically generated by SVGR from assets/icons/*.svg.
 * Do not edit this file or add other components to this directory.
 */
import type { SVGProps } from 'react'
export function OnlineStatusAccountOfflineIcon(
  props: SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={25}
      fill='none'
      {...props}
    >
      <mask
        id='online-status-account-offline_svg__a'
        width={24}
        height={25}
        x={0}
        y={0}
        maskUnits='userSpaceOnUse'
        style={{
          maskType: 'alpha',
        }}
      >
        <path fill='#D9D9D9' d='M0 .733h24v24H0z' />
      </mask>
      <g mask='url(#online-status-account-offline_svg__a)'>
        <path
          fill='#E36857'
          d='m19.8 23.333-2.65-2.6H6.5q-2.3 0-3.9-1.6t-1.6-3.9q0-1.926 1.188-3.425A5.3 5.3 0 0 1 5.25 9.883q.075-.2.15-.388.075-.187.15-.412L1.4 4.933l1.4-1.4 18.4 18.4zm-13.3-4.6h8.65l-8.05-8.05a7 7 0 0 0-.075.525 5 5 0 0 0-.025.525h-.5q-1.45 0-2.475 1.025A3.37 3.37 0 0 0 3 15.233q0 1.449 1.025 2.475A3.37 3.37 0 0 0 6.5 18.733m15.1.75-1.45-1.4q.425-.351.638-.813.212-.462.212-1.037 0-1.05-.725-1.775-.725-.726-1.775-.725H17v-2q0-2.076-1.463-3.538T12 6.733q-.675 0-1.3.162-.626.164-1.2.513l-1.45-1.45a7 7 0 0 1 1.862-.913A6.9 6.9 0 0 1 12 4.733q2.925 0 4.962 2.037T19 11.733q1.725.2 2.863 1.487A4.4 4.4 0 0 1 23 16.233a4.38 4.38 0 0 1-1.4 3.25'
        />
      </g>
    </svg>
  )
}
