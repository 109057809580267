/*
 * Automatically generated by SVGR from assets/icons/*.svg.
 * Do not edit this file or add other components to this directory.
 */
import type { SVGProps } from 'react'
export function TemperatureSubtractIcon(
  props: SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={18}
      height={18}
      fill='none'
      {...props}
    >
      <mask
        id='temperature-subtract_svg__a'
        width={18}
        height={18}
        x={0}
        y={0}
        maskUnits='userSpaceOnUse'
        style={{
          maskType: 'alpha',
        }}
      >
        <path fill='#2196F3' d='M0 0h18v18H0z' />
      </mask>
      <g mask='url(#temperature-subtract_svg__a)'>
        <path fill='#9DA1A9' d='M3.75 9.75v-1.5h10.5v1.5z' />
      </g>
    </svg>
  )
}
