/*
 * Automatically generated by SVGR from assets/icons/*.svg.
 * Do not edit this file or add other components to this directory.
 */
import type { SVGProps } from 'react'
export function OnlineStatusDeviceOfflineIcon(
  props: SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={25}
      fill='none'
      {...props}
    >
      <path
        fill='#E36857'
        d='m1.411 4.931 1.373-1.373 18.432 18.431-1.373 1.373z'
      />
      <path
        fill='#E36857'
        fillRule='evenodd'
        d='M12 23.362q-2.925 0-4.962-2.037T5 16.362v-8q0-1.198.342-2.248L7.028 7.8Q7 8.076 7 8.362v8q0 2.076 1.463 3.538T12 21.362q2.075 0 3.537-1.462.996-.996 1.314-2.276l1.58 1.58a6.9 6.9 0 0 1-1.469 2.12Q14.926 23.364 12 23.363m6.975-6.36q.025-.315.025-.64v-8q0-2.925-2.038-4.962T12 1.362 7.038 3.4a7.4 7.4 0 0 0-.768.897l1.452 1.451q.305-.488.74-.923Q9.927 3.362 12 3.362q2.075 0 3.537 1.463Q17 6.288 17 8.362v6.664zM15 12.361q.424 0 .713-.287a.97.97 0 0 0 .287-.713.97.97 0 0 0-.287-.712.97.97 0 0 0-.713-.288.97.97 0 0 0-.713.288.97.97 0 0 0-.287.712q0 .426.287.713.288.287.713.287m-6 3q.424 0 .713-.287a.97.97 0 0 0 .287-.713.97.97 0 0 0-.287-.712.97.97 0 0 0-.713-.288.97.97 0 0 0-.713.288.97.97 0 0 0-.287.712q0 .426.287.713.288.287.713.287m0 3q.424 0 .713-.287a.97.97 0 0 0 .287-.713.97.97 0 0 0-.287-.712.97.97 0 0 0-.713-.288.97.97 0 0 0-.713.288.97.97 0 0 0-.287.712q0 .425.287.713.288.287.713.287m3 0q.424 0 .713-.287a.97.97 0 0 0 .287-.713.97.97 0 0 0-.287-.712.97.97 0 0 0-.713-.288.97.97 0 0 0-.713.288.97.97 0 0 0-.287.712q0 .425.287.713.288.287.713.287m3.713-9.287a.97.97 0 0 1-.713.287.97.97 0 0 1-.713-.287.97.97 0 0 1-.287-.713q0-.424.287-.712A.97.97 0 0 1 15 7.362q.424 0 .713.288.287.287.287.712 0 .426-.287.713m-3.749.287q.424 0 .713-.287a.97.97 0 0 0 .287-.713.97.97 0 0 0-.287-.712.97.97 0 0 0-.713-.288.97.97 0 0 0-.712.288.97.97 0 0 0-.288.712q0 .426.288.713.287.287.712.287'
        clipRule='evenodd'
      />
    </svg>
  )
}
