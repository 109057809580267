/*
 * Automatically generated by SVGR from assets/icons/*.svg.
 * Do not edit this file or add other components to this directory.
 */
import type { SVGProps } from 'react'
export function ClimateSettingScheduleIcon(
  props: SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={19}
      height={22}
      fill='none'
      {...props}
    >
      <path
        fill='#232B3A'
        fillRule='evenodd'
        d='M13 9.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8m0 1.5a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11m1.547-3.172.89-.89L13.75 5.25V3H12.5v2.766zM5 1c.797 0 1.54.233 2.163.635a7 7 0 0 0-.866 1.842A2 2 0 0 0 3 5v9.667l-.401.534a3 3 0 1 0 4.802 0L7 14.667v-5.56a7 7 0 0 0 2 2.138V14a5 5 0 1 1-8 0V5a4 4 0 0 1 4-4M4 15.268V8h2v7.268A2 2 0 0 1 5 19a2 2 0 0 1-1-3.732'
        clipRule='evenodd'
      />
    </svg>
  )
}
