/*
 * Automatically generated by SVGR from assets/icons/*.svg.
 * Do not edit this file or add other components to this directory.
 */
import type { SVGProps } from 'react'
export function CloseIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={24}
      fill='none'
      {...props}
    >
      <mask
        id='close_svg__a'
        width={24}
        height={24}
        x={0}
        y={0}
        maskUnits='userSpaceOnUse'
        style={{
          maskType: 'alpha',
        }}
      >
        <path fill='#D9D9D9' d='M0 0h24v24H0z' />
      </mask>
      <g mask='url(#close_svg__a)'>
        <path
          fill='#B9BEC5'
          d='M6.4 19 5 17.6l5.6-5.6L5 6.4 6.4 5l5.6 5.6L17.6 5 19 6.4 13.4 12l5.6 5.6-1.4 1.4-5.6-5.6z'
        />
      </g>
    </svg>
  )
}
