/*
 * Automatically generated by SVGR from assets/icons/*.svg.
 * Do not edit this file or add other components to this directory.
 */
import type { SVGProps } from 'react'
export function BeeIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={33}
      height={33}
      fill='none'
      {...props}
    >
      <path
        fill='#5DBE77'
        d='M23.902 25.469c-.18.263-3.2-.2-4.15-5.212-.95-5.01 1.214-5.411 1.214-5.411s2.746-.79 4.086 4.938c1.225 5.21-1.15 5.685-1.15 5.685m-8.437-6.917h-.01c-1.468-.98-1.996-1.853-1.996-1.853 1.257-.18 2.724-.958 2.724-.958l.813.568v.01l.834.548s-.18 1.643.106 2.874c0 0-1.014-.168-2.46-1.168m-9.63-5.327s-.422-2.4 4.931-3.18c5.881-.852 6.156 2 6.156 2s.412 2.18-4.646 3.138c-5.068.958-6.62-1.695-6.44-1.958m14.149-6.117c.612.421.77 1.242.348 1.853a1.33 1.33 0 0 1-1.858.347 1.32 1.32 0 0 1-.348-1.852 1.33 1.33 0 0 1 1.858-.348m4.445 3.022c.612.41.77 1.242.36 1.853-.413.61-1.247.758-1.86.347a1.32 1.32 0 0 1-.348-1.853 1.33 1.33 0 0 1 1.859-.347m2.523 10.58c-.37-3.053-2.006-5.496-2.64-6.19 0 0-.063-.021-.01-.148.053-.126.158-.495.158-.495s0-.042.074-.063.834-.179 1.426-1.042c.59-.863.971-2.653-1.046-4.022 0 0-.01 0-.031-.094-.01-.085-.338-1.548-1.225-2.159-.887-.6-2.376-.368-2.46-.347s-.095 0-.095 0c-2.017-1.369-3.548-.358-4.14.505-.59.864-.453 1.632-.453 1.706s-.032.084-.032.084l-.401.327c-.095.084-.138.042-.138.042-.886-.337-3.769-.969-6.747-.19-2.977.78-4.16 1.874-4.814 2.832-.655.958-1.024 2.874 1.024 4.264s4.952 1.263 4.952 1.263c.084 0 .095.063.095.063.707 2.506 3.051 3.97 3.21 4.075.158.116 2.386 1.747 4.973 1.484 0 0 .063-.01.095.074 0 0 .95 2.737 2.988 4.127 2.048 1.39 3.696.347 4.35-.61.655-.959 1.246-2.454.887-5.496m-9.914 3.074s-1.964.084-4.213-1.443c-2.25-1.526-2.893-3.38-2.893-3.38-.053-.146-.106-.052-.106-.052-.602.885-.781 1.39-.443 2.8.327 1.359 1.974 2.517 2.09 2.601.116.074 1.795 1.18 3.189.99 1.436-.2 1.848-.558 2.45-1.443 0 0 .063-.084-.085-.073m-4.424 2.39s-.95 0-1.943-.664l-.507.737.507-.747c-.992-.674-1.34-1.558-1.34-1.558-.16-.243-.201 0-.201 0-.55 2.116.39 3.032.475 3.105.095.042 1.298.58 3.072-.705 0 0 .211-.126-.074-.179'
      />
    </svg>
  )
}
