/*
 * Automatically generated by SVGR from assets/icons/*.svg.
 * Do not edit this file or add other components to this directory.
 */
import type { SVGProps } from 'react'
export function ThermostatHeatCoolIcon(
  props: SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={19}
      height={18}
      fill='none'
      {...props}
    >
      <path
        fill='#6B95FF'
        d='M13.633 17.56v-2.021l-1.583 1.558-.681-.681 2.264-2.265v-.974h-.974l-2.264 2.265-.682-.682 1.558-1.583h-2.02v-.974h2.02l-1.558-1.582.682-.682 2.264 2.264h.974v-.974L11.37 8.965l.681-.682 1.583 1.558v-2.02h.974v2.02l1.583-1.558.681.682-2.264 2.264v.974h.974l2.264-2.264.682.682-1.558 1.582h2.02v.974h-2.02l1.558 1.583-.682.682-2.264-2.265h-.974v.974l2.264 2.265-.681.681-1.583-1.558v2.02z'
      />
      <path
        fill='#FC8E28'
        d='M.91 6.201q0-1.389.652-2.475.651-1.085 1.433-1.827.783-.741 1.434-1.131l.651-.39v1.746q0 .49.326.775.325.285.73.284.222 0 .423-.092.202-.093.372-.305l.234-.29a4.2 4.2 0 0 1 1.512 1.541q.573.987.573 2.164 0 1.165-.56 2.125A4.25 4.25 0 0 1 7.217 9.84q.222-.317.346-.695t.124-.8a2.58 2.58 0 0 0-.756-1.84l-1.85-1.84-1.838 1.84a2.7 2.7 0 0 0-.573.847q-.195.463-.196.993 0 .423.124.8.123.378.345.695a4.25 4.25 0 0 1-1.472-1.515A4.14 4.14 0 0 1 .91 6.2m4.17-.053 1.108 1.099q.221.225.339.503.117.278.117.596 0 .648-.456 1.105a1.5 1.5 0 0 1-1.108.457q-.651 0-1.107-.457a1.5 1.5 0 0 1-.456-1.105q0-.305.117-.59.116-.285.339-.509z'
      />
    </svg>
  )
}
